import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { FileSaver } from '@ionic-native/file';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

//let apiUrl = 'http://192.168.0.104/OPD_IPD_WEBAPI/search.php';
//let apiUrl1 = 'http://192.168.0.104:5000';
let apiUrl1 = 'https://dev-coboticsai-touchstone.appspot.com'

// let apiUrl1='https://smart-logistics-release-2.uk.r.appspot.com';
// let apiUrl1='http://127.0.0.1:8080/'
  //  let apiUrl1='https://touchstone-sandbox.uc.r.appspot.com'
// let apiUrl1='https://backend-touchstone-refresh.uc.r.appspot.com';

// let apiUrl1 = 'https://test-coboticsai-touchstone.appspot.com';

 

@Injectable({
  providedIn: 'root'
})
export class WebapiService { 
  shipperdata:any=[];
  useroles=[];
  lang = [
    {"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
    {"code":"aa","name":"Afar","nativeName":"Afaraf"},
    {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
    {"code":"ak","name":"Akan","nativeName":"Akan"},
    {"code":"sq","name":"Albanian","nativeName":"Shqip"},
    {"code":"am","name":"Amharic","nativeName":"አማርኛ"},
    {"code":"ar","name":"Arabic","nativeName":"العربية"},
    {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
    {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
    {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
    {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
    {"code":"ae","name":"Avestan","nativeName":"avesta"},
    {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
    {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
    {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
    {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
    {"code":"eu","name":"Basque","nativeName":"euskara, euskera"},
    {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
    {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
    {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
    {"code":"bi","name":"Bislama","nativeName":"Bislama"},
    {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
    {"code":"br","name":"Breton","nativeName":"brezhoneg"},
    {"code":"bg","name":"Bulgarian","nativeName":"български език"},
    {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
    {"code":"ca","name":"Catalan; Valencian","nativeName":"Català"},
    {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
    {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
    {"code":"ny","name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
    {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
    {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
    {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
    {"code":"co","name":"Corsican","nativeName":"corsu, lingua corsa"},
    {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
    {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
    {"code":"cs","name":"Czech","nativeName":"česky, čeština"},
    {"code":"da","name":"Danish","nativeName":"dansk"},
    {"code":"dv","name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
    {"code":"nl","name":"Dutch","nativeName":"Nederlands, Vlaams"},
    {"code":"en","name":"English","nativeName":"English"},
    {"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
    {"code":"et","name":"Estonian","nativeName":"eesti, eesti keel"},
    {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
    {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
    {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
    {"code":"fi","name":"Finnish","nativeName":"suomi, suomen kieli"},
    {"code":"fr","name":"French","nativeName":"français, langue française"},
    {"code":"ff","name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"gl","name":"Galician","nativeName":"Galego"},
    {"code":"ka","name":"Georgian","nativeName":"ქართული"},
    {"code":"de","name":"German","nativeName":"Deutsch"},
    {"code":"el","name":"Greek, Modern","nativeName":"Ελληνικά"},
    {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
    {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
    {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
    {"code":"ha","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
    {"code":"he","name":"Hebrew (modern)","nativeName":"עברית"},
    {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
    {"code":"hi","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
    {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
    {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
    {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
    {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
    {"code":"ie","name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
    {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
    {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
    {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
    {"code":"io","name":"Ido","nativeName":"Ido"},
    {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
    {"code":"it","name":"Italian","nativeName":"Italiano"},
    {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
    {"code":"ja","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
    {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
    {"code":"kl","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
    {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
    {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
    {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
    {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
    {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
    {"code":"ki","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
    {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
    {"code":"ky","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
    {"code":"kv","name":"Komi","nativeName":"коми кыв"},
    {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
    {"code":"ko","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
    {"code":"ku","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
    {"code":"kj","name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
    {"code":"la","name":"Latin","nativeName":"latine, lingua latina"},
    {"code":"lb","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
    {"code":"lg","name":"Luganda","nativeName":"Luganda"},
    {"code":"li","name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
    {"code":"ln","name":"Lingala","nativeName":"Lingála"},
    {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
    {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
    {"code":"lu","name":"Luba-Katanga","nativeName":""},
    {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
    {"code":"gv","name":"Manx","nativeName":"Gaelg, Gailck"},
    {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
    {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
    {"code":"ms","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
    {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
    {"code":"mt","name":"Maltese","nativeName":"Malti"},
    {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
    {"code":"mr","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
    {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
    {"code":"mn","name":"Mongolian","nativeName":"монгол"},
    {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
    {"code":"nv","name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
    {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
    {"code":"nd","name":"North Ndebele","nativeName":"isiNdebele"},
    {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
    {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
    {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
    {"code":"no","name":"Norwegian","nativeName":"Norsk"},
    {"code":"ii","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
    {"code":"nr","name":"South Ndebele","nativeName":"isiNdebele"},
    {"code":"oc","name":"Occitan","nativeName":"Occitan"},
    {"code":"oj","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
    {"code":"cu","name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"om","name":"Oromo","nativeName":"Afaan Oromoo"},
    {"code":"or","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
    {"code":"os","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
    {"code":"pa","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
    {"code":"pi","name":"Pāli","nativeName":"पाऴि"},
    {"code":"fa","name":"Persian","nativeName":"فارسی"},
    {"code":"pl","name":"Polish","nativeName":"polski"},
    {"code":"ps","name":"Pashto, Pushto","nativeName":"پښتو"},
    {"code":"pt","name":"Portuguese","nativeName":"Português"},
    {"code":"qu","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
    {"code":"rm","name":"Romansh","nativeName":"rumantsch grischun"},
    {"code":"rn","name":"Kirundi","nativeName":"kiRundi"},
    {"code":"ro","name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
    {"code":"ru","name":"Russian","nativeName":"русский язык"},
    {"code":"sa","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
    {"code":"sc","name":"Sardinian","nativeName":"sardu"},
    {"code":"sd","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
    {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
    {"code":"sm","name":"Samoan","nativeName":"gagana faa Samoa"},
    {"code":"sg","name":"Sango","nativeName":"yângâ tî sängö"},
    {"code":"sr","name":"Serbian","nativeName":"српски језик"},
    {"code":"gd","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
    {"code":"sn","name":"Shona","nativeName":"chiShona"},
    {"code":"si","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
    {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
    {"code":"sl","name":"Slovene","nativeName":"slovenščina"},
    {"code":"so","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
    {"code":"st","name":"Southern Sotho","nativeName":"Sesotho"},
    {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
    {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
    {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
    {"code":"ss","name":"Swati","nativeName":"SiSwati"},
    {"code":"sv","name":"Swedish","nativeName":"svenska"},
    {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
    {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
    {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
    {"code":"th","name":"Thai","nativeName":"ไทย"},
    {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
    {"code":"bo","name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
    {"code":"tk","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
    {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
    {"code":"tn","name":"Tswana","nativeName":"Setswana"},
    {"code":"to","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
    {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
    {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
    {"code":"tt","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
    {"code":"tw","name":"Twi","nativeName":"Twi"},
    {"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
    {"code":"ug","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
    {"code":"uk","name":"Ukrainian","nativeName":"українська"},
    {"code":"ur","name":"Urdu","nativeName":"اردو"},
    {"code":"uz","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
    {"code":"ve","name":"Venda","nativeName":"Tshivenḓa"},
    {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
    {"code":"vo","name":"Volapük","nativeName":"Volapük"},
    {"code":"wa","name":"Walloon","nativeName":"Walon"},
    {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
    {"code":"wo","name":"Wolof","nativeName":"Wollof"},
    {"code":"fy","name":"Western Frisian","nativeName":"Frysk"},
    {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
    {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
    {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
    {"code":"za","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
  ];
  selectedSearchfilter: any = {

    "pricemodel": "ATA",
    "partner": "Partner_1",
    "Service_Level": "STD",
    "origin": "HKG",
    "destination": "LAX",
    "userData":"ATA",
    "type":'P'
  }
  expirflag=false;
  forcastlist:any
  seletedlane:any;
  userdata={
    iid:"",
    semail:'',
    uconfig:'',
    Role:'',
    otherDetails1:''
  }
  cvalues={
    high_op_spend:5000,
    minimim_priority:0.97,
    min_total_assesorial:25,
    mom_delta:0.25

  }
  marketoutlooktitle:any
  totalcount = 0
  ccount = 0
  icount = 0
  corrlist = []
  marketoutlooklist=[]
  corr_list = []

  im_list = []
  loading: any;
  dashboard_count:any; 

  readFile(filePath: string): Observable<string> {
    return this.http.get(filePath, { responseType: 'text' });
  }

  categoryuri =[
    {
        "uri": "dmoz",
        "label": "dmoz",
        "parentUri": ""
    },
    {
        "uri": "news",
        "label": "news",
        "parentUri": ""
    },
    {
        "uri": "dmoz/Arts",
        "label": "dmoz/Arts",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Business",
        "label": "dmoz/Business",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Computers",
        "label": "dmoz/Computers",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Games",
        "label": "dmoz/Games",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Health",
        "label": "dmoz/Health",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Home",
        "label": "dmoz/Home",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Recreation",
        "label": "dmoz/Recreation",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Science",
        "label": "dmoz/Science",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Shopping",
        "label": "dmoz/Shopping",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Society",
        "label": "dmoz/Society",
        "parentUri": "dmoz"
    },
    {
        "uri": "dmoz/Sports",
        "label": "dmoz/Sports",
        "parentUri": "dmoz"
    },
    {
        "uri": "news/Arts_and_Entertainment",
        "label": "news/Arts and Entertainment",
        "parentUri": "news"
    },
    {
        "uri": "news/Business",
        "label": "news/Business",
        "parentUri": "news"
    },
    {
        "uri": "news/Environment",
        "label": "news/Environment",
        "parentUri": "news"
    },
    {
        "uri": "news/Health",
        "label": "news/Health",
        "parentUri": "news"
    },
    {
        "uri": "news/Politics",
        "label": "news/Politics",
        "parentUri": "news"
    },
    {
        "uri": "news/Science",
        "label": "news/Science",
        "parentUri": "news"
    },
    {
        "uri": "news/Sports",
        "label": "news/Sports",
        "parentUri": "news"
    }
]
  constructor(public router:Router,public http: HttpClient, public loadingController: LoadingController) {
    //console.log('Hello DatacontrollerProvider Provider');
  }
  key = CryptoJS.enc.Utf8.parse('VC1TVJHtzW8p3BQtkwe7qEMHNZpta4N7');

  
  encrypt(msgString) {
    // msgString is expected to be Utf8 encoded
    var iv :any= CryptoJS.lib.WordArray.random(16);
    var encrypted = CryptoJS.AES.encrypt(msgString, this.key, {
        iv: iv
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

decrypt(ciphertextStr) {
    var ciphertext= CryptoJS.enc.Base64.parse(ciphertextStr);
    // split IV and ciphertext
    var iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;
   
    // decryption
    //@ts-ignore
    var decrypted = CryptoJS.AES.decrypt({ciphertext:ciphertext}, this.key, {
        iv: iv
    }); 
    
    return decrypted.toString(CryptoJS.enc.Utf8);
}
  
  logout()
  {
    this.userdata.iid=="";

    localStorage.clear();
    this.router.navigateByUrl('/signup');
  }
 
 

  // Method to call protected API
  postData(credentials, type): Promise<any> {
    return new Promise((resolve, reject) =>{

      const token = localStorage.getItem('token');  // Retrieve the token from localStorage
      const headers = new HttpHeaders({
      'Authorization': 'Bearer '+token,
      'Content-Type': 'application/json'   // Add the Bearer token to the Authorization header
    });

     this.http.post<any>(
      `${apiUrl1}/${type}`, 
      credentials,
      { headers, withCredentials: true }  // Send cookies with the request
    ).subscribe(res => {
      resolve(res);

    }, (err) => {

      console.error('Login failed:', err.status);
      if (err.status === 401||err.status===422) {
        if(this.expirflag==false)
         {
            this.expirflag=true;
            alert("Your session has expired or the token is invalid. Please log in again.");
             this.logout();
         }
        
        
      }
      reject(err);
    });
  });

  
  }

  

  //final for file uplaod with token
  postData1(credentials, type) {

    const token = localStorage.getItem('token'); 
    var header = new HttpHeaders({
      'Authorization': 'Bearer '+token  // Add the Bearer token to the Authorization header
    });
    // header.append('Accept', 'application/json');
    // if (token) {
    //   header.append('Authorization', `Bearer ${token}`);
    // }
    // console.log(header)
    return new Promise((resolve, reject) => {
     // //console.log(apiUrl1);
      ////console.log(credentials);
      this.http.post(apiUrl1 + "/" + type, credentials,{headers:header})
        .subscribe(res => {
          resolve(res);

        }, (err) => {
          if (err.status === 401||err.status===422) {
            if(this.expirflag==false)
              {
                 this.expirflag=true;
                 alert("Your session has expired or the token is invalid. Please log in again.");
                  this.logout();
              }
          }
          reject(err);

        });
    });

  }


  translate(text){
    return new Promise((resolve, reject) => {
    this.http.get("https://translation.googleapis.com/language/translate/v2?key=AIzaSyDoe6vJ3aMktPt4QCryE-xznr35YZmqkqs&q="+text+"&target=en")
    .subscribe(res => {
      resolve(res);

    }, (err) => {

      reject(err);
    });
  });

  }
  

  downloadCsv(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }


  saveTextAsFile (data, filename){

      if(!data) {
          console.error('Console.save: No data')
          return;
      }

      if(!filename) filename = 'console.json'

      var blob = new Blob([data], {type: 'text/xlsx'}),
          e    = document.createEvent('MouseEvents'),
          a    = document.createElement('a')
// FOR IE:

if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
}
else{
    var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/xlsx', a.download, a.href].join(':');
    e.initEvent('click', true, false)
    a.dispatchEvent(e);
}
    }






    download(type)
    {
      return new Promise((resolve, reject) => {
   
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
      console.log(val)

        var fileName = type+".csv"

       this.saveTextAsFile(val, fileName);
        console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
        reject(err);
      });
        });
    }

    download_data(type,data)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download_demand_sensing_data',data,{headers,responseType: 'text'}).subscribe((val) => {

                console.log(val);

      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
        var fileName = type
       this.saveTextAsFile(val, fileName);
        //console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }
    download_allo(type,filename)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download_allo/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
        var fileName = filename
       this.saveTextAsFile(val, fileName);
        //console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }

    download_ratebook(type,filename)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download_ratebookm/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
        var fileName = filename
       this.saveTextAsFile(val, fileName);
        //console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }

    download_warehouse(type,filename)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download_warehouse/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
        var fileName = filename
       this.saveTextAsFile(val, fileName);
        //console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }
    
    download_F(type,filename)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
      this.http.post(apiUrl1 +'/download/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
      console.log(val)
        var fileName = filename+".csv"
       this.saveTextAsFile(val, fileName);
        //console.log('success!');
        resolve({'result':1});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }
    
    // used
    getfile_content(type)
    {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');  // Retrieve the token from localStorage
        const headers = new HttpHeaders({
        'Authorization': 'Bearer '+token        
      });
   
      this.http.post(apiUrl1 +'/download/'+type,{},{headers,responseType: 'text'}).subscribe((val) => {
      //var blob = new Blob([val], {type: "text/plain;charset=utf-8"}); 
        var fileName = type+".csv"
      
        resolve({'result':val});

      }, (err) => {
        if (err.status === 401||err.status===422) {
          if(this.expirflag==false)
            {
               this.expirflag=true;
               alert("Your session has expired or the token is invalid. Please log in again.");
                this.logout();
            }
        }
      });
        });
    }
   
    
  
  async presentLoadingWithOptions(e) {
    const loading = await this.loadingController.create({
      spinner: null,
      duration: 10000,
      message: 'Please wait...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }

  async presentLoading(e) {

    this.loading = await this.loadingController.create({
      message: 'Please wait...',
      duration: 2200

    });

    return await this.loading.present();


    //console.log('Loading dismissed!');
  }

  async LoaderonDidDismiss(e) {

    // const { role, data } = await  this.loading.dismiss();IndexIgnore .htaccess */.??* *~ *# */HEADER* */README* */_vti*

  }
// Http Options
httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })


}
  
  postDatareport(credentials, type) {


    var api="https://www.touchstone-ai.com/mail/mailreport.php"
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/json');
    return new Promise((resolve, reject) => {
      //console.log(apiUrl1);
      //console.log(credentials);
      this.http.post(api + "?action=" + type, credentials, this.httpOptions)
        .subscribe(res => {
          resolve(res);

        }, (err) => {
    
          reject(err);
        });
    });


    
  }
  postDatareportp(credentials, type) {


    var api="https://www.touchstone-ai.com/mail/mailreport_partner.php"
    var header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Accept', 'application/json');
    return new Promise((resolve, reject) => {
      //console.log(apiUrl1);
      //console.log(credentials);
      this.http.post(api + "?action=" + type, credentials, this.httpOptions)
        .subscribe(res => {
          resolve(res);

        }, (err) => {
    
          reject(err);
        });
    });


    
  }

  addaccesslog(module){
    // iUserid =_json['iUserid']
    // sUseremail =_json['sUseremail']
    // dtStarttime =_json['dtStarttime']
    // dtEndtime =_json['dtEndtime']
    // iModule =_json['iModule']
    var userid =localStorage.getItem('user');
    var email =localStorage.getItem('email');
    var filter ={'iUserid':userid,'sUseremail':email,'iModule':module}
    // else
    //   filter ={'startdate':this.endofweek.add(1,'days').format('MM/DD/YYYY'),nweek:4,weeklist:[]}

      
    this.postData(filter, 'accesslog').then((result) => {

      //console.log('access',result);

    });
  }
}
